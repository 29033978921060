.success-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 60px 30px;
  width: 100%;
  max-width: 640px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .12);

  @media (max-width: 768px) {
    padding: 30px 15px;
    box-shadow: none;
  }

  &__icon {
    margin: 0 !important;
    line-height: 1;
    vertical-align: middle;
    font-size: 5em !important;

    @media (max-width: 768px) {
      font-size: 4em !important;
    }
  }

  &__text {
    color: #767676;
    font-size: 28px;
    line-height: 1.2em;
    font-family: 'PT Sans', sans-serif;
    white-space: pre-line;
    text-align: center;
    padding-top: 25px;

    @media (max-width: 768px) {
      font-size: 20px;
      padding-top: 15px;
    }
  }

  &__go-back-button-wrapper {
    width: 100%;
    margin-top: 15px;
    display: flex;

    .button {
      margin: 0 auto !important;
    }
  }
}
