.s-alert-box {
  cursor: pointer;
}

.s-alert-box-inner {
  text-align: center;
}

.s-alert-error {
  background: rgba(231, 76, 60, 0.9)
}

.s-alert-success {
  background: rgba(39, 174, 96, 0.9)
}

.s-alert-warning {
  background: rgba(242, 113, 28, 0.9)
}

.s-alert-info {
  background: rgba(33, 133, 208, 0.9)
}

.s-alert-close {
  margin-top: 16px;
}
