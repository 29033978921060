.quick-answers-list {
  padding: 20px 0;

  h3,
  h4 {
    line-height: 1;
    margin: 0;
  }

  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 20px 20px 20px;

    input {
      width: 100%;
      height: 38px;
    }
  }

  &__template {
    height: 82px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    border-top: 1px solid #ececec;
    padding: 0 20px;

    &:last-child {
      border-bottom: 1px solid #ececec;
      margin-bottom: 43px;
    }

    h4 {
      font-weight: 600;
      line-height: 17px;
      font-size: 14px;
      margin-bottom: 12px;
    }

    p {
      font-size: 12px;
      line-height: 14px;
      font-weight: normal;
      margin: 0;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
    }
  }
}
