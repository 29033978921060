.appTableFiltersRow {
  display: flex;
  align-items: end;

  label {
    color: rgb(107, 107, 107);
		font-size: 13px;
  }
}

.appTableSearchRefreshGroup {
  display: flex;
  justify-content: end;
  width: 100%;
  margin-left: auto;
}

.appTable-wrapper {
  .item-per-page-caption {
    line-height: 3em;
  }

  .item-per-page-dropdown {
    margin-left: 0.5em;
  }
}

.appTable-outer {
  height: 100%;
  margin: 15px 0 !important;

  &_scrolled {
    overflow-x: auto;
  }
}

.appTable {
  height: 100%;
  th {
    white-space: pre !important;

    &.sorted {
      position: relative;
      padding-right: 25px !important;

      &::after {
        height: fit-content !important;
        top: 50% !important;
        position: absolute !important;
        transform: translateY(-50%) !important;
        right: 11px !important;
      }
    }
  }

  th,
  td {
    @media (max-width: 767px) {
      text-align: center !important;
    }
  }

  tr > td:not(:last-child) {
    @media (max-width: 767px) {
      margin-bottom: 0.25em !important;
    }
  }

  &__mobile-cell-header {
    font-weight: bold;
    display: none;
    margin-bottom: 0.25em;

    @media (max-width: 767px) {
      display: block;
    }
  }

  &__top-row {
    margin: 0 1em 1em !important;

    @media (max-width: 767px) {
      margin: 0.5em 0 !important;

      .label {
        width: 100%;
        margin: 2px 0 !important;
      }
    }
  }

  &__top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end !important;
    margin-left: 1em !important;
    margin-right: 1em !important;

    .full_search {
      display: flex;
    }

    @media (max-width: 767px) {
      margin: 0 !important;
    }
  }

  &__buttons {
    width: 100%;

    .button:last-child {
      margin-right: 0;
    }

    @media (max-width: 767px) {
      display: flex;
      width: 100%;
      margin: 0;

      .button:first-child {
        flex-grow: 1;
      }
    }
  }

  &__filters {
    display: flex;
    flex-direction: column;

    button,
    .button {
      height: 38px !important;
    }

    .filters-mb {
      margin-bottom: 10px;
    }

    .datepicker-range {
      display: flex;

      button {
        margin: 0 !important;
      }

      .field {
        min-width: unset !important;
      }

      .rdt input {
        display: none !important;
      }

      &__fakeInput input:focus {
        border-color: rgba(34,36,38,.15) !important;
        caret-color: transparent !important;
      }
    }

    .date-picker__input-field {
      width: fit-content !important;

      input {
        width: 140px !important;
        text-align: start;
      }
    }

    .button__input-field {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    .button__input-field .button {
      margin: 0 !important;
    }

    .filters-button {
      display: flex;

      .rdt {
        margin-right: 0.25em;
      }
    }

    .field {
      min-width: 220px;
    }

    @media (max-width: 1076px) {
      .field {
        min-width: 175px;
      }
    }

    @media (max-width: 767px) {
      flex: 0 0 100%;
      flex-wrap: wrap;
      margin: 0.5em 0;

      > .field {
        display: block;
        width: 100%;
        min-width: 220px;
        padding: 0 !important;

        label {
          display: block !important;
          margin: 0 0 0.28571429rem !important;
        }
      }

      .button {
        flex: 0 0 100%;
        margin: 2px 0 !important;
      }

      .dropdown {
        display: block !important;
      }
    }
  }

  &__filters-description {
    margin-left: 10px;

    @media (max-width: 767px) {
      width: 100%;
      margin: 2px 0;
      text-align: center;
    }
  }

  &__search {
    margin-left: auto;
    margin-right: 0.25em;
    width: 180px;

    &_large {
      width: 360px;
    }

    @media (max-width: 767px) {
      flex: 0 0 100%;
      margin: 0.5em 0 !important;
    }
  }

  &__refresh-button {
    margin: 0 !important;

    @media (max-width: 767px) {
      flex: 0 0 100%;
      margin: 0.5em 0 1em !important;
    }
  }

  &__half-width-cell {
    width: 3.125%;
  }

  &__cell-with-nested-table {
    padding: 0 !important;
    height: 100%;

    > table {
      height: 100%;
      border: none !important;

      tr:not(:first-of-type) {
        td {
          border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
        }
      }
    }
  }

  &__pages-counter {
    float: right;
    margin-right: 10px;
    line-height: 3em;

    @media (max-width: 767px) {
      margin: 0;
    }
  }

  .pagination {
    @media (max-width: 767px) {
      width: 100%;
      margin: 0 !important;
    }
  }

  &__right-side-actions {
    margin: 0 0.25em 0 0;
  }

  &__checkbox-picker {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    height: 100%;
    position: relative;

    label {
      position: absolute;
      top: -20px;
      white-space: pre;
      left: 0;
      display: inline-block;
      vertical-align: baseline;
      font-size: 0.92857143em;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.87);
      text-transform: none;
    }
  }

  &__radio-picker {
    display: flex;
    height: 100%;
    position: relative;
    margin-right: 15px;

    label {
      position: absolute;
      top: -20px;
      white-space: pre;
      left: 0;
      display: inline-block;
      vertical-align: baseline;
      font-size: 0.92857143em;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.87);
      text-transform: none;
    }
  }

  &__select-filter {
    min-width: 252px;

    & > div > div:first-child {
      margin-top: -1px;
    }
  }
}
