.app-date-time-picker {
  display: flex;
  align-items: center;

  .rdt {
    width: 100%;
  }

  .input-group-prepend {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0px;
    padding-right: 0.5rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
  }

  .input-group-append {
    margin-left: -1px;
    display: flex;

    .input-group-text {
      display: flex;
      align-items: center;
      padding: 0.375rem 0.75rem;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      text-align: center;
      white-space: nowrap;
      background-color: #eff9ff;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      height: 38px;
    }
  }
}