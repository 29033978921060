i.icon.custom-icon-list-dark {
  background-image: url('../images/list-icon-dark.svg');
  background-size: contain;
  height: 100% !important;
  background-repeat: no-repeat;
  background-position: center;
}

i.icon.custom-icon-list-light {
  background-image: url('../images/list-icon-light.svg');
  background-size: contain;
  height: 100% !important;
  background-repeat: no-repeat;
  background-position: center;
}

i.icon.custom-icon-kanban-light {
  background-image: url('../images/kanban-icon-light.svg');
  background-size: contain;
  height: 100% !important;
  background-repeat: no-repeat;
  background-position: center;
}

i.icon.custom-icon-kanban-dark {
  background-image: url('../images/kanban-icon-dark.svg');
  background-size: contain;
  height: 100% !important;
  background-repeat: no-repeat;
  background-position: center;
}

i.icon.custom-icon-male {
  background-image: url('../images/maleIcon.svg');
  background-size: contain;
  height: 100% !important;
  background-repeat: no-repeat;
  background-position: center;
}

i.icon.custom-icon-female {
  background-image: url('../images/femaleIcon.svg');
  background-size: contain;
  height: 100% !important;
  background-repeat: no-repeat;
  background-position: center;
}
