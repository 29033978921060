.loading-wrapper {
  width: 100%;
  // height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-bg {
  background-color: rgba(0, 0, 0, 0) !important;
}
