.main-menu {
  position: fixed !important;
  display: flex !important;
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;

  hr {
    margin: 0px;
    border-color: #505050;
  }
  &.overlay + .pusher {
    width: 100% !important;
  }

  .item::before {
    opacity: 0;
  }

  &__menu-wrapper {
    flex-grow: 1;
    display: flex;
  }

  &__menu {
    > .title {
      padding: 0.8em 1.14em !important;
      border-top: 1px solid rgba(255, 255, 255, .08);

      &:hover {
        background: rgba(255, 255, 255, .08);
      }
    }

    > .title.active {
      background: rgba(255, 255, 255, 0.03) !important;
      border-bottom: 1px solid rgba(255, 255, 255, .08);
    }

    > .content.active {
      background: rgba(255, 255, 255, 0.03) !important;
    }

    > .content {
      padding: 0 !important;
    }

    .title {
      display: flex;
      justify-content: space-between;
      border: none;
    }

    .title .icon {
      margin: 0 0 0 0.5em !important;
    }
  }

  &__menu-item {
    font-size: inherit !important;

    &_selected {
      background: rgba(255, 255, 255, 0.08)!important;
    }

    &_in-footer {
      margin-top: auto;

      & ~ & {
        margin-top: initial;
      }
    }
  }

  &__menu-items-group {
    margin-bottom: 15px;
    margin-top: 15px;

    &:last-of-type {
      margin-bottom: 80px;
    }

    &:first-of-type {
      border-top: none;
    }

    .item {
      font-size: 1.1em;
    }

    border-bottom: 1px solid rgba(255, 255, 255, .08);
  }
}

.left-bandle {
  left: 25% !important;
  top: 0.75em !important;
}

.left-bandle-subscriber {
  left: 50% !important;
  top: 0.75em !important;
}