.emoji-picker {
  position: absolute;
  top: 4px;
  right: 4px;
}

.emoji-picker i {
  margin: 0 !important;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}

.emoji-picker.open i,
.emoji-picker i:hover {
  color: rgba(0, 0, 0, 0.8);
}

.emoji-picker__popover {
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 100%;
}

.emoji-picker__cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}