.quick-answers-dialog {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin: 0;
    }

    input {
      width: 335px;
    }
  }

  &__templates {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 680px;
  }

  &__template:not(:last-child) {
    margin-bottom: 36px;
  }

  .template {
    line-height: 17px;

    label {
      padding-left: 35px !important;
    }

    input,
    label::before,
    label::after {
      top: 16px !important;
    }

    &__title {
      font-weight: 600;
      font-size: 14px;
    }

    p {
      font-weight: normal;
      font-size: 14px;
      margin: 12px 0 0 0;
      color: #000 !important;
    }
  }
}
