@import 'vars';
@import 'lnr-icons';
@import 'modal';
@import 'custom-icons';
@import 'p7icons';
@import 'animate.css/animate.min.css';

body {
  #root,
  .ui.menu,
  h1,
  h2,
  h3,
  h4,
  h5,
  button,
  a,
  dd,
  dt,
  time {
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  }

  @media (min-width: 768px) {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

html,
html {
  overflow: hidden;
}

body {
  #root,
  .ui.menu,
  h1,
  h2,
  h3,
  h4,
  h5,
  button,
  a,
  dd,
  dt,
  input,
  div,
  p,
  span,
  time {
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  }
}

#root {
  @media (min-width: 768px) {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

/* Sidebar */
.pushable {
  transform: none !important;
}

.pushable,
.pusher {
  overflow: visible !important;
}
/* /Sidebar */

/* Page */
#root {
  height: 100%;
}

.page-header {
  display: flex;
  align-items: center;
  margin-left: 0.5em;

  @media (max-width: 767px) {
    margin-left: 0 !important;
  }

  > button:first-of-type {
    margin-left: 10px;
  }
}

.page-content {
  width: calc(100% - 260px);
  transition-property: all;

  &.full-width {
    width: 100%;
  }

  &.dimmed {
    height: 100%;
    overflow: hidden !important;
  }
}

.page-top-buttons {
  margin: 0 1em 1em 1em;

  .button:last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    margin: 0;

    .button:first-child {
      flex-grow: 1;
    }
  }
}
/* /Page */

/* Button */
.button {
  img.icon {
    padding: 10px;
  }
}

.ui.fluid[class*='left labeled'].icon.button {
  padding-left: 3.4em !important;
  padding-right: 1em !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

i.icon.lnr {
  font-family: Linearicons-Free !important;
  font-size: 16px !important;
  font-weight: 900 !important;
}
/* /Button */

/* Checkbox */
.ui.toggle.toggle_green.checkbox input:checked {
  ~ .box:before,
  ~ label:before,
  &:focus ~ .box:before,
  &:focus ~ label:before {
    background: #79d469 !important;
  }
}
/* /Checkbox */

/* Input */
input {
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
/* /Input */

/* Text */
.meta-text {
  font-size: 0.8em;
  opacity: 0.7;
}

.text-muted {
  opacity: 0.7;
}

.info-text {
  &:first-child {
    margin-top: 0 !important;
  }

  &_red {
    color: #db2828;
  }
}

.underlined-link {
  text-decoration: underline !important;
}

.table-note-link {
  text-decoration: underline !important;
  color: inherit !important;
}

.no-wrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}
/* /Text */

/* Alignment */
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.align-center {
  text-align: center;
}

.loading-center {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* /Alignment */

/* Alert */
.fixed-alerts {
  position: fixed;
  top: 0;
  z-index: 1001;

  &.full-width {
    width: 100% !important;
    transform: none !important;
  }
}
/* /Alert */

/* Table */
.table-labels-centered {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  white-space: nowrap;

  .label {
    margin: 0.14285714em;
  }
}

.compact.table {
  margin: 0px !important;
}

.table-labels-nowrap {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.table-status-label {
  vertical-align: middle !important;
}

.ui.sortable.table th.disabled:hover {
  background: #f9fafb;
  color: rgba(0, 0, 0, 0.87) !important;
}

/* /Table */

/* Menu */
.ui.menu .active.item {
  background: rgba(black, 0.1);

  &:hover {
    background: rgba(black, 0.15);
  }
}
/* /Menu */

.ui.divided.items > .item {
  &:first-child {
    padding-top: 10px !important;
  }

  &:last-child {
    padding-bottom: 10px !important;
  }
}

.smooth-font {
  -webkit-font-smoothing: antialiased;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

// WYSIWYG

.ql-snow {
  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}

// Colors

$text-colors: blue, green, orange, pink, purple, red, teal, yellow, black, grey, white;
@each $tc in $text-colors {
  .text.#{$tc} {
    color: var(--#{$tc}-color);
  }
}
