@media only screen and (min-width: 768px) {
  .ui.small.modal > .header:not(.ui),
  .ui.modal > .header:not(.ui),
  .ui.modal > .content {
    padding: 20px 40px 20px 40px !important;
  }

  .ui.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
    font-size: 16px !important;
  }

  .ui.modal {
    .ui.grid > .column:not(.row):not(:first-child),
    .ui.grid > .row > .column:not(:first-child),
    .ui.grid > .row > .column:only-child {
      padding-right: 0 !important;
    }

    .ui.grid > .column:not(.row):not(:last-child),
    .ui.grid > .row > .column:not(:last-child),
    .ui.grid > .row > .column:only-child {
      padding-left: 0 !important;
    }

    .ui.grid .row + .ui.divider {
      margin: 1rem 0;
    }
  }
}

.ui.modal > .actions {
  padding: 14px 40px !important;

  button:last-child{
    margin-right: 0 !important;
  }
}
