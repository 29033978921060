.infinite-scroller {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  transform: translateY(0);
  
  &__list {
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    transition: transform 0.5s;
    overflow: auto;
  }

  &__loader {
    transition: transform 0.5s;
    position: absolute;
    width: 100%;
  }

  &__initial-loader {
    position: absolute;
    width: 100%;
    background: #fff;
    z-index: 2;
    transition: opacity 0.5s;
    opacity: 1;

    &--hide {
      opacity: 0;
      z-index: 0;
      display: none;
      visibility: hidden;
    }
  }

  &_reversed {
    display: flex;
    flex-direction: column-reverse;
  }
}
