.cropper-dropzone {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border: 0;
  height: 100%;
  overflow: hidden;
}

.cropper-controls {
  margin-top: 20px;
  text-align: center;
}

.cropper-itself {
  max-width: 550px;
  max-height: 400px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.cropper-dropzone_without-img {
  border: 2px dashed rgba(34, 36, 38, 0.15);
  justify-content: center!important;
  color: #747E84;

  &:hover {
    border: 2px dashed black;
  }

  &:focus {
    outline: none;
  }

  .cropper-dropzone__image {
    max-width: 100% !important;
    width: 100px;
  }

  .cropper-dropzone__text {
    margin: 15px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
  }
}

.cropper-dropzone_with-img {
  img {
    border-radius: inherit;
    max-width: 100%;
    height: 100%!important;
  }

  .cropper-dropzone__image-label {
    background-color: transparent !important;
    padding: 10px !important;
  }

  .cropper-dropzone__image-label i {
    margin-left: 0 !important;
  }
}

.cropper-dropzone-wrapper {
  position: relative;
  margin-bottom: 20px;
  max-width: 100%;

  &:focus {
    outline: none;
  }

  &.mini {
    padding-top: 140px;
    max-width: 140px;

    .cropper-dropzone {
      &_without-img {
        .cropper-dropzone {
          &__text {
            font-size: 8px;
          }
    
          &__image {
            width: 30px;
          }
        }
      }
    }
  }

  &.middle {
    padding-top: 254px;
    max-width: 254px;

    .cropper-dropzone {
      &_without-img {
        .cropper-dropzone {
          &__text {
            font-size: 10px;
          }
    
          &__image {
            width: 40px;
          }
        }
      }
    }
  }

  &_1x1 {
    padding-top: 40%;
  }

  &_original {
    padding-top: 40%;
  }

  &_3x2 {
    padding-top: 66.66%;
  }

  &_2x1 {
    padding-top: 50%;
  }

  &_6x1 {
    padding-top: 16.66%;
    margin-bottom: 0;

    .cropper-dropzone {
      border-radius: 0;
    }

    .cropper-dropzone__image-label {
      padding: 2px !important;
    }

    .cropper-dropzone_without-img {
      .cropper-dropzone__image {
        margin: 0;
        padding: 2px;
        max-height: 100%;
      }
    }
  }
}
