.buttons-select {
  flex-wrap: wrap;

  &_full-width {
    width: 100%;
  }

  &__button {
    i {
      display: inline !important;
    }
  }
}