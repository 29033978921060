.dropZoneContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  border: 2px dashed #dededf;
  transition: all 0.2s linear;
  height: 276px;

  &:not(.dragAccepted):not(.dragRejected):hover {
    border-color: var(--green-color);
  }

  img {
    margin-bottom: 7px;
  }

  span {
    color: #000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }
}

.dragAccepted {
  border-color: var(--green-color);
}

.dragRejected {
  border-color: var(--red-color);
}
