.error-page {
  position: relative;
  margin: 0 auto;
  padding: 60px 30px;
  width: 100%;
  max-width: 640px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .12);

  @media (max-width: 768px) {
    padding: 30px 15px;
    box-shadow: none;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__image {
    max-width: 150px;
  }

  &__text {
    color: #767676;
    font-size: 28px;
    line-height: 1.2em;
    font-family: 'PT Sans', sans-serif;
    white-space: pre-line;

    @media (max-width: 768px) {
      text-align: center;
      padding-top: 15px;
      font-size: 20px;
    }
  }

  &__go-back-button-wrapper {
    width: 100%;
    margin-top: 15px;
  }
}