.app-infographics-circle {
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  canvas {
    z-index: 1;
  }

  &:not(:last-child) {
    margin-right: 50px;
  }

  &__inner {
    width: 260px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__center {
    position: absolute;
    font-size: 20px;
  }

  &__legend {
    display: grid;
    grid-gap: 15px;
    margin-left: 25px;
  }

  &__legend-item {
    display: grid;
    align-items: center;
    justify-content: start;
    grid-gap: 15px;
    grid-auto-flow: column;
  }

  &__legend-item-label {
    width: 60px;
    height: 28px;
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre;

    p {
      white-space: pre;
    }
  }

  &__empty-chart {
    width: 260px;
    height: 260px;
    border: 40px solid #d8d8d8;
    position: absolute;
    border-radius: 50%;
  }
}
