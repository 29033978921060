.date-input {
  &__field-wrapper {
    position: relative;
  }

  &__complete-icon {
    position: absolute !important;
    top: 9px;
    right: 8px;
  }
}