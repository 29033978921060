$primary-violet-color: #6435c9;

:root {
  // Semantic colors
  --red-color: #db2828;
  --orange-color: #f2711c;
  --yellow-color: #fbbd08;
  --olive-color: #b5cc18;
  --green-color: #21ba45;
  --teal-color: #00b5ad;
  --blue-color: #2185d0;
  --violet-color: #6435c9;
  --purple-color: #a333c8;
  --pink-color: #e03997;
  --brown-color: #a5673f;
  --grey-color: #E0E1E2;
  --black-color: #1b1c1d;
}
