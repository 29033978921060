$pe-7s-font-path: "~pe7-icon/dist/fonts";

@import "~pe7-icon/dist/scss/pe-icon-7-stroke.scss";

.pe-7s-chat::before,
.pe-7s-chat::after {
  content: '';
}

.pe-7s-chat--active,
.pe-7s-chat {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat !important;
  background-position: top center !important;
}

.pe-7s-chat--active {
  background: url('./icons/chat-icon--active.svg');
}

.pe-7s-chat {
  background: url('./icons/chat-icon.svg');
}
