.hovering-dropdown-menu {
  padding: .78571429em 0.4em .78571429em 1em !important;

  &__icon-wrapper {
    display: flex;
    justify-content: center;
  }

  .text {
    display: none !important;
  }

  .item {
    display: flex !important;
    align-items: center;

    > img {
      width: 1.18em !important;
      padding: 0 0.09em !important;
      height: auto;
    }
  }
}
