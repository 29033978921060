.site-header {
  flex-wrap: wrap;

  &__toggle-sidebar-icon {
    margin: 0 !important;
  }

  @media (max-width: 767px) {
    > .menu {
      &:first-child {
        width: 100%;
        border-bottom: 1px solid rgba(34, 36, 38, 0.1);

        .item:last-child {
          flex-grow: 1;
          justify-content: center;
        }
      }

      &:last-child {
        width: 100%;
        margin-left: 0 !important;

        .item:first-child {
          flex-grow: 1;
          justify-content: center;
        }
      }
    }
  }

  &__hidden-on-mobile {
    @media (max-width: 767px) {
      display: none !important;
    }
  }

  &__icon-on-mobile {
    @media (max-width: 767px) {
      i {
        margin: 0 !important;
      }

      span {
        display: none;
      }
    }
  }

  &__cb-select {
    padding: 0 !important;

    & > .dropdown {
      height: 100%;
      border: none !important;
      border-right: 1px solid rgba(34, 36, 38, 0.15) !important;
      border-radius: 0 !important;
      display: flex !important;
      align-items: center;
    }

    input {
      height: 100%;
    }

    &--only {
      pointer-events: none;

      .ui.search.dropdown > .text,
      input,
      & > .dropdown {
        cursor: default !important;
      }

      .dropdown.icon {
        display: none;
      }
    }

    &:not(.site-header__cb-select--only) {
      * {
        cursor: pointer !important;
      }
    }

    .dropdown.icon {
      top: 15px !important;
    }

    .ui.selection.active.dropdown .menu {
      border-color: #d4d4d5 !important;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15) !important;
    }

    .ui.selection.active.dropdown {
      box-shadow: none !important;
    }

    .ui.dropdown > .text {
      font-weight: 700 !important;
      font-size: 18px;
      color: inherit !important;
    }
  }
}

.tada {
  &.transition {
    animation-duration: 3s !important;
    animation-iteration-count: infinite !important;
  }
}
